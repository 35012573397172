@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Thin.otf);
  font-weight: 100;
}

@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Ultralight.otf);
  font-weight: 200;
}

@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Semibold.otf);
  font-weight: 600;
}

@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Black.otf);
  font-weight: 800;
}

@font-face {
  font-family: "SF Pro Rounded";
  src: url(./fonts/SF-Pro-Rounded-Heavy.otf);
  font-weight: 900;
}

body {
  background-color: rgb(250, 250, 251) !important;
  color: rgb(38, 38, 38);
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "SF Pro Rounded", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #b5b9c7;
  border-radius: 3px;
}

.link {
  width: 100%;
  text-decoration: none;
}

.mapboxgl-popup {
  max-width: 200px;
  min-width: 120px;
}

.mapboxgl-popup-content {
  padding: 10px !important;
}

.mapboxgl-popup-content p,
.mapboxgl-popup-content h3 {
  margin-top: 0;
  margin-bottom: 5px;
}
.pswp__img {
  max-height: 100vh !important; /* Đảm bảo ảnh không vượt quá chiều cao màn hình */
  object-fit: contain !important; /* Giữ nguyên tỷ lệ ảnh */
}